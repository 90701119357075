<template>
  <section style="background-color: #ededed; min-height: 100vh; width: 100%">
    <div
      class="w-100 p-2 d-flex justify-content-around"
      style="
        background-color: #009d80 !important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        position: sticky;
        top: 0;
        z-index: 40;
      "
    >
      <div class="w-70 mt-2" style="min-width: 50%">
        <p style="color: #efe829">Affiliate - Earn 500K KES per month</p>
      </div>

      <div class="w-30">
        <router-link to="/">
          <i
            class="bi bi-x"
            style="
              color: white;
              cursor: pointer;
              font-size: 20px;
              font-weight: bold;
            "
          ></i>
        </router-link>
      </div>
    </div>

    <div
      class="w-90 mt-3 d-flex justify-content-between"
      style="
        background-color: #ededed !important;
        padding-left: 9px;
        padding-right: 9px;
      "
    >
      <div
        class="tabs"
        :class="{ activeTab: activeTab === 'panel' }"
        @click="setActiveTab('panel')"
      >
        PANEL
      </div>
      <div
        class="tabs"
        :class="{ activeTab: activeTab === 'referrals' }"
        @click="setActiveTab('referrals')"
      >
        REFERRALS
      </div>
      <div
        class="tabs"
        :class="{ activeTab: activeTab === 'report' }"
        @click="setActiveTab('report')"
      >
        REPORT
      </div>
      <div
        class="tabs"
        :class="{ activeTab: activeTab === 'payment' }"
        @click="setActiveTab('payment')"
      >
        PAYMENT
      </div>
      <div
        class="tabs"
        :class="{ activeTab: activeTab === 'faq' }"
        @click="setActiveTab('faq')"
      >
        FAQ
      </div>
      <div
        class="tabs"
        :class="{ activeTab: activeTab === 'tutorial' }"
        @click="setActiveTab('tutorial')"
      >
        TUTORIAL
      </div>
    </div>

    <section v-if="activeTab === 'panel'">
      <div class="mt-3 d-flex flex-column today-container">
        <div
          class="d-flex g-2"
          style="border-bottom: 1px solid; padding: 2px; margin-left: 5px"
        >
          <p style="color: #808080; font-weight: bolder">Today</p>
          <div
            style="
              min-width: 5%;
              background-color: #f9a21a;
              margin-left: 8px;
              height: 23px;
              border-radius: 50%;
              margin-top: 3px;
            "
          >
            <i class="bi bi-question" style="color: white"></i>
          </div>
        </div>

        <div class="d-flex justify-content-between today">
          <div class="d-flex justify-content-around today-left">
            <div style="min-width: 25%">
              <div class="today-left-counter">0</div>
              <div class="today-left-text">Signups</div>
            </div>

            <div style="min-width: 25%">
              <div class="today-left-counter">0</div>
              <div class="today-left-text">New Players</div>
            </div>
          </div>

          <div
            class="d-flex justify-content-around"
            style="gap: 7px; min-width: 50%; padding: 10px"
          >
            <div style="min-width: 25%">
              <div class="today-left-counter">0</div>
              <div class="today-left-text">Team Wager</div>
            </div>

            <div style="min-width: 25%">
              <div class="today-left-counter">0</div>
              <div
                style="
                  font-size: 12px;
                  text-align: center;
                  font-weight: 600;
                  white-space: normal;
                  word-wrap: break-word;
                "
              >
                Commission
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 d-flex flex-column today-container">
        <div
          class="d-flex g-2"
          style="border-bottom: 1px solid; padding: 2px; margin-left: 5px"
        >
          <p style="color: #808080; font-weight: bolder">Total</p>

          <div
            style="
              min-width: 5%;
              background-color: #f9a21a;
              margin-left: 8px;
              height: 23px;
              border-radius: 50%;
              margin-top: 3px;
            "
          >
            <i class="bi bi-question" style="color: white"></i>
          </div>
        </div>

        <div class="today">
          <div class="d-flex justify-content-around today-left">
            <div style="min-width: 25%">
              <div class="today-left-counter">0</div>
              <div class="today-left-text">Signups</div>
            </div>

            <div style="min-width: 25%">
              <div class="today-left-counter">0</div>
              <div class="today-left-text">Total Players</div>
            </div>
          </div>

          <div
            class="d-flex justify-content-around"
            style="gap: 7px; min-width: 50%; padding: 10px"
          >
            <div style="min-width: 25%">
              <div class="today-left-counter">0</div>
              <div class="today-left-text">Total Wager</div>
            </div>

            <div style="min-width: 25%">
              <div class="today-left-counter">0</div>
              <div
                style="
                  font-size: 12px;
                  text-align: center;
                  font-weight: 600;
                  white-space: normal;
                  word-wrap: break-word;
                "
              >
                Commission
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 d-flex flex-column today-container">
        <div
          class="d-flex g-2"
          style="border-bottom: 1px solid; padding: 2px; margin-left: 5px"
        >
          <p style="color: #808080; font-weight: bold">Commission</p>
          <div
            style="
              min-width: 5%;
              background-color: #f9a21a;
              margin-left: 8px;
              height: 23px;
              border-radius: 50%;
              margin-top: 3px;
            "
          >
            <i class="bi bi-question" style="color: white"></i>
          </div>
        </div>

        <div class="d-flex justify-content-between p-1 today">
          <div class="d-flex justify-content-center col-5">
            <div class="commission-left">
              <i class="bi bi-trophy-fill icon"></i>
              <p class="icon-p">
                Agent Level
                <span
                  style="background-color: #a6a6a6"
                  class="text-white p-1 rounded-circle"
                  >i</span
                >
              </p>
            </div>
          </div>

          <div
            class="d-flex flex-column justify-content-around commission-right"
          >
            <div class="d-flex justify-content-around">
              <div class="commission-right-paid-unpaid">
                <div class="today-left-counter">0.00</div>
                <div class="today-left-text">Total paid</div>
              </div>

              <div class="commission-right-paid-unpaid">
                <div class="today-left-counter">0.00</div>
                <div
                  style="
                    font-size: 12px;
                    text-align: center;
                    font-weight: 600;
                    white-space: normal;
                    word-wrap: break-word;
                  "
                >
                  Unpaid
                </div>
              </div>
            </div>

            <div>
              <button class="transfer-wallet">Transfer To Wallet</button>

              <p class="transfer-text">
                Transfer will be Available once you reach KES 100
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 d-flex flex-column today-container">
        <p class="p-1 fw-bolder" style="color: #808080">
          Share By email or on social media
        </p>

        <div class="d-flex justify-content-around" style="width: 100%">
          <div class="d-flex flex-column" style="width: 20%">
            <a
              href="https://www.facebook.com/Powerbet/"
              target="_blank"
              class="btn btn-fab btn-round btn-facebook"
            >
              <img
                loading="lazy"
                class="social-btn"
                src="https://d8060roj23kfv.cloudfront.net/assets/icons/facebook.svg"
              />
            </a>
            <span class="text-center words-span">FaceBook</span>
          </div>

          <div class="d-flex flex-column" style="width: 20%">
            <a
              href="https://www.facebook.com/Powerbet/"
              target="_blank"
              class="btn btn-fab btn-round btn-facebook"
            >
              <img
                loading="lazy"
                class="social-btn"
                src="https://d8060roj23kfv.cloudfront.net/assets/icons/facebook.svg"
              />
            </a>
            <span class="text-center words-span">WhatsApp</span>
          </div>

          <div class="d-flex flex-column" style="width: 20%">
            <a
              href="https://www.facebook.com/Powerbet/"
              target="_blank"
              class="btn btn-fab btn-round btn-facebook"
            >
              <img
                loading="lazy"
                class="social-btn"
                src="https://d8060roj23kfv.cloudfront.net/assets/icons/facebook.svg"
              />
            </a>
            <span class="text-center words-span">Telegram</span>
          </div>

          <div class="d-flex flex-column" style="width: 20%">
            <a
              href="https://www.facebook.com/Powerbet/"
              target="_blank"
              class="btn btn-fab btn-round btn-facebook"
            >
              <img
                loading="lazy"
                class="social-btn"
                src="https://d8060roj23kfv.cloudfront.net/assets/icons/facebook.svg"
              />
            </a>
            <span class="text-center words-span">Twitter</span>
          </div>

          <div class="d-flex flex-column" style="width: 20%">
            <a
              href="https://www.facebook.com/Powerbet/"
              target="_blank"
              class="btn btn-fab btn-round btn-facebook"
            >
              <img
                loading="lazy"
                class="social-btn"
                src="https://d8060roj23kfv.cloudfront.net/assets/icons/facebook.svg"
              />
            </a>
            <span class="text-center words-span">Email</span>
          </div>
        </div>

        <p style="margin-top: 14px; font-size: 14px; text-align: center">
          Share this referral link to your friends and followers
        </p>

        <div class="copy-div">
          <p style="color: #80808b; font-weight: bold">
            Gamewina.com/ke/t......
          </p>

          <p style="color: #5eb3e9; font-weight: bold">Copy</p>
        </div>
      </div>

      <div class="mt-3 d-flex flex-column today-container">
        <div
          class="d-flex g-2"
          style="border-bottom: 1px solid; padding: 2px; margin-left: 5px"
        >
          <p style="color: #808080; font-weight: bold">
            Commission reward rules
          </p>
          <div
            style="
              max-width: 5%;
              background-color: #f9a21a;
              margin-left: 8px;
              height: 23px;
              border-radius: 50%;
              margin-top: 3px;
            "
          >
            <i class="bi bi-question" style="color: white"></i>
          </div>
        </div>

        <p class="text-center fw-bolder mt-2" style="color: #442729">
          Make Money by referring friends to GameWina, You earn more than 25%
          commission rewards!
        </p>

        <div class="d-flex justify-content-around mt-2">
          <div class="col-3">
            <img
              src="../../public/assets/dollar-affiliate.jpeg"
              style="width: 60px; height: 60px; object-fit: cover"
            />
          </div>

          <div class="col-9">
            <p style="color: red; font-weight: 600">
              Number of Referrals > 100
            </p>
            <p style="color: red; font-weight: 600">
              Commission > ksh. 50,000 every month!
            </p>
          </div>
        </div>

        <p class="text-center fw-bolder mt-2" style="color: #442729">
          Unlimited development of subordinates
        </p>

        <img
          src="../../public/assets/dollar-affiliate.jpeg"
          style="
            width: 100px;
            height: 100px;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
          "
        />

        <p class="text-center mt-2" style="color: #442729">
          1.You will receive a different commission percentage every time a
          player referred by you places a bet,
          <span style="color: red"> wins or loses.</span>
        </p>
        <p class="text-center" style="color: #442729; margin-top: 2px">
          2. The system calculates the commission every 24 hours
        </p>
      </div>
    </section>

    <section v-if="activeTab === 'referrals'">
      <div
        style="
          width: 90%;
          background-color: white;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          min-height: 80vh;
        "
      >
        <input
          type="date"
          style="
            width: 60%;
            margin-top: 10px;
            border: 1px solid #442729;
            padding: 4px;
            border-radius: 5px;
          "
        />

        <transition name="slide-up">
          <div v-if="showCalendar" class="calendar-modal">
            <DatePicker v-model="selectedDate" @confirm="toggleCalendar" />
            <div class="calendar-footer">
              <button @click="toggleCalendar" class="confirm-btn">
                Confirm
              </button>
            </div>
          </div>
        </transition>
      </div>
    </section>

    <section v-if="activeTab === 'report'"></section>

    <section v-if="activeTab === 'payment'"></section>

    <section v-if="activeTab === 'faq'"></section>
  </section>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "Affiliates",
  components: {
    DatePicker,
  },
  data() {
    return {
      activeTab: "panel",
      showCalendar: false,
      selectedDate: null,
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    toggleCalendar() {
      console.log("toggle");
      this.showCalendar = !this.showCalendar;
    },
  },
};
</script>

<style scoped>
.tabs {
  font-size: 12px;
  width: 15;
  color: #9c8ead;
}

.activeTab {
  border-bottom: 2px solid green;
  font-weight: bold;
  color: #009d80;
}

.today-container {
  background-color: #ffffff;
  border-radius: 9px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.today {
  width: 100%;
  display: flex;
  justify-content: around;
}

.today-left {
  gap: 7px;
  width: 50%;
  padding: 10px;
}

.today-left-counter {
  display: flex;
  justify-content: center;
  /* #383838 */
  color: black;
  font-weight: bolder;
  font-size: 18px;
}

.today-left-text {
  font-size: 12px;
  text-align: center;
  font-weight: 600;
}

.commission-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 2px;
  cursor: pointer;
}

.commission-right {
  gap: 7px;
  min-width: 60%;
  padding: 10px;
}

.commission-right-paid-unpaid {
  width: 30%;
}

.transfer-wallet {
  width: 100%;
  color: #8a8a8a;
  background-color: #ffffff !important;
  border: 1px solid;
  border-radius: 3px;
}

.transfer-text {
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}

.icon {
  font-size: 46px;
  text-align: center;
  color: #009d80;
}

.icon-p {
  text-align: center;
  color: #009d80;
  font-weight: 600;
}

.words-span {
  font-size: 12px;
  white-space: normal;
  word-wrap: break-word;
}

.copy-div {
  background-color: #f9f9f9;
  width: 90%;
  border-radius: 3px;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding: 4px;
}

/* Style for the sliding calendar */
.calendar-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 100;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

/* Slide-up transition */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.4s ease;
}

.slide-up-enter, .slide-up-leave-to /* 'leave-to' for Vue 2.1.8+ */ {
  transform: translateY(100%);
}

/* Footer styling for calendar */
.calendar-footer {
  text-align: center;
  padding: 10px;
}

.confirm-btn {
  background-color: #7a00cb;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.date-input-container {
  display: flex;
  align-items: center;
  margin: 20px;
  padding: 10px;
}

.date-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.bi-calendar {
  font-size: 1.5em;
  cursor: pointer;
}

@media screen and(max-width:310px) {
  .today {
    flex-direction: column;
  }
}
</style>